import "./Headimgstyle.css";
import React from 'react';
import headerImg from "../assets/headerimg.jpg";
import { Link } from "react-router-dom";

const Headimg = () => {
  return (
    <div className="header">
        <div className="mask">
            <img className="headerimg" src={headerImg} alt="headerimg"/>
        </div>
        <div className="content">
            <h1>روژاکـس</h1>
            <p>طراح سایت و مدیر سئو</p>
            <div>
                <Link to="/project" className="btn" >مشاهده نمونه کارها</Link>
                <Link to="/contact" className="btn" >اطلاعات تماس</Link>
            </div>
        </div>
    </div>
  )
}

export default Headimg
