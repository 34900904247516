import React from "react";
import Navbar from "../components/Navbar";
import Headimg from "../components/Headimg";
import Footer from "../components/Footer";

const Home = () => {
  return <div>
    <Navbar/>
    <Headimg />
    <Footer />
  </div>
};

export default Home;