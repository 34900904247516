import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Headimg2 from "../components/Headimg2";


const Contact = () => {
  return <div>
    <Navbar/>
    <Headimg2 heading="تماس با ما" text="" />
    <Footer />
  </div>
};

export default Contact;