import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Headimg2 from "../components/Headimg2";


const Project = () => {
  return <div>
    <Navbar />
    <Headimg2 heading="نمونه کارها" text="چند نمونه از وبسایت های طراحی شده توسط من!"/>
    <Footer />
  </div>
};

export default Project;