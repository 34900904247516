import "./Headimgstyle2.css";
import React, { Component } from 'react';
import headerimg2 from "../assets/headerimg2.jpg";


class Headimg2 extends Component {
  render(){
    return (
      <div className="projhead">
          <div className="headermask">
              <img className="headerimg2" src={headerimg2} alt="headerimg2"/>
          </div>
          <div className="contents">
              <h1>{this.props.heading}</h1>
              <p>{this.props.text}</p>
          </div>
      </div>
    )
  }
};

export default Headimg2;
